import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2 } from "../utils/display"

const selectedRadioValue = (radioButtons) =>
  radioButtons.filter(button => button.checked)[0].value

export default class extends Controller {
  static targets = [
    'associationKindSelect',
    'equipmentSelect',
    'areaSelect',
    'selectLabel',
  ];

  connect() {
    if (this.hasAreaSelectTarget) {
      this.displayRoom();
    }
  }

  onAssociationKindChange() {
    if (selectedRadioValue(this.associationKindSelectTargets) === 'equipment') {
      this.displayEquipment();
    } else if (selectedRadioValue(this.associationKindSelectTargets) === 'room' || 'outdoor_zone') {
      this.displayRoom();
    }
  }

  displayEquipment() {
    const label = this.equipmentSelectTarget.dataset.label
    showV2(this.equipmentSelectTarget)
    this.selectLabelTarget.innerHTML = label
    hideV2(this.areaSelectTarget.closest('.relative'))
    showV2(this.equipmentSelectTarget.closest('.relative'))
    this.areaSelectTarget.value = null
    hideV2(this.areaSelectTarget)
  }

  displayRoom() {
    const label = this.areaSelectTarget.dataset.label;
    showV2(this.areaSelectTarget);
    this.selectLabelTarget.innerHTML = label;
    hideV2(this.equipmentSelectTarget.closest('.relative'))
    showV2(this.areaSelectTarget.closest('.relative'))
    this.equipmentSelectTarget.value = null;
    hideV2(this.equipmentSelectTarget);
  }
}
