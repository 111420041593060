import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.tooltip = this.element.querySelector(".tooltip")
    this.arrow = this.element.querySelector(".tooltip-third__arrow") || this.element.querySelector(".tooltip__arrow")

    this.boundShowTooltip = this.positioningTooltip.bind(this)
    this.element.addEventListener("mouseenter", this.boundShowTooltip)
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.boundShowTooltip)
  }

  positioningTooltip() {
    this.tooltip.style.top = `-${this.tooltip.offsetHeight + 4}px` // Adjust the value according to the need
    this.arrow.style.left = `${Math.floor(this.element.getBoundingClientRect().left - this.tooltip.getBoundingClientRect().left + (this.element.offsetWidth / 2) - 1)}px`
    if (this.element.dataset.position === 'center') {
      this.arrow.style.left = `${Math.floor(this.tooltip.offsetWidth / 2)- 1}px`
      this.tooltip.style.right = 'unset'
      this.tooltip.style.left = `${Math.floor((this.element.offsetWidth / 2) - (this.tooltip.offsetWidth / 2))}px` // Adjust the value according to the need
    }
  }
}