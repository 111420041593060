import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  dispatchCloseSidePanel(event) {
    if (event.detail.success) {
      this.dispatch('closeSidePanel')
    }
  }

  submit({target, currentTarget}) {
    if (this.formTarget.checkValidity()) {
      if (target.type === 'button') {target.disabled = true}
      if (currentTarget.type === 'button') {currentTarget.disabled = true}
      this.dispatch('submitted')
      this.formTarget.requestSubmit()
    } else {
      this.formTarget.reportValidity()
    }
  }
}