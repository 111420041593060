import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['value'];

  connect() {
    this.dispatchChange()
  }

  decrement() {
    if (!this.element.closest('.disabled')) {
      this.valueTarget.stepDown();
      this.dispatchChange()
    }
  }

  increment() {
    if (!this.element.closest('.disabled')) {
      this.valueTarget.stepUp()
      this.dispatchChange()
    }
  }

  dispatchChange() {
    if (this.element.dataset.dispatch) {
      const {name, value} = this.valueTarget
      this.dispatch('fieldChanged', { detail: {field: name, value}})
    }
  }
}
