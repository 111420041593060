import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2 } from "../utils/display";
import { addHours, endOfHour, setHours, parse, setMinutes } from 'date-fns';

export default class extends Controller {
  static targets = ['heatmap', 'skeleton']

  fetchHeatmap({detail}) {
    showV2(this.skeletonTarget)
    hideV2(this.heatmapTarget)
    const { startDate, endDate } = detail
    const url = new URL(this.heatmapTarget.dataset.heatmapUrl)

    url.searchParams.append('start_date', startDate)
    url.searchParams.append('end_date', endDate)

    fetch(url, {headers: {'Content-Type': 'application/json'}})
      .then(response => response.json())
      .then(({data}) => {
        hideV2(this.skeletonTarget)
        showV2(this.heatmapTarget)
        this.dispatchUpdate(JSON.parse(data))
        this.updateEvaluationDate(startDate, endDate)
      })
      .catch(error => console.warn(error))
  }

  dispatchUpdate(series) {
    const event = new CustomEvent('update-chart', {detail: { series }})
    document.querySelector('#heatmap').dispatchEvent(event)
  }

  updateEvaluationDate(startDate, endDate) {
    const event = new CustomEvent('update-limit', {detail: { startDate, endDate }})
    document.querySelector("#evaluation_date").dispatchEvent(event)
  }

  fetchCo2Result() {
    let date = document.querySelector('#evaluation_date')?.value
    const time = document.querySelector('#evaluation_time_slot')?.value

    if (date && time) {
      date = parse(date, 'dd/MM/yyyy', new Date())
      
      fetch(this.buildUrl(date, time), {headers: {'Content-Type': 'application/json'}})
        .then(response => response.json())
        .then(({data}) => this.updateMeasures(JSON.parse(data)))
        .catch(error => console.warn(error))
    }
  }

  buildUrl(date, time) {
    const url = new URL(this.heatmapTarget.dataset.url)
    const { startDate, endDate } = this.dates(date, time)
    url.searchParams.append('start_date', startDate)
    url.searchParams.append('end_date', endDate)

    return url
  }

  dates(date, time) {
    const [hour, minutes] = time.split(':').map(Number)
    let startDate = setHours(date, hour)
    startDate = setMinutes(startDate, minutes)
    let endDate = endOfHour(addHours(startDate, 1))

    return {startDate, endDate}
  }

  updateMeasures({maxValue, minValue, above800, above1500 }) {
    document.querySelector('#max_value').value = maxValue
    document.querySelector('#min_value').value = minValue
    document.querySelector('#nb_values_above_800ppm').value = above800
    document.querySelector('#nb_values_above_1500ppm').value = above1500
  }
}