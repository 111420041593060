import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import {
  close,
  hideV2,
  showV2,
  activate,
  desactivate,
  toggleOpen
 } from "../utils/display"

export default class extends Controller {
  static targets = [
    'formRoomAssociationType',
    'formRoomAssociation',
    'clearAssociationButton',
    'formRoomAssociationPanel',
    'formRoomAssociationItem',
    'selectedFormRoomAssociation',
    'sensorSelect',
    'roomSelect',
    'nameField',
    'floorField'
  ]

  connect() {
    let associationType = null
    let value = null

    if (this.hasRoomSelectTarget && this.roomSelectTarget.value) {
      value = this.roomSelectTarget.value
      associationType = 'rooms'
    } else if (this.hasSensorSelectTarget && this.sensorSelectTarget.value) {
      value = this.sensorSelectTarget.value
      associationType = 'sensors'
    }

    if (associationType) {this.initField({associationType, value})}

    if(this.hasformRoomAssociationTarget) useClickOutside(this, {element: this.formRoomAssociationTarget})
  }

  clickOutside(event) {
    if (this.hasFormRoomAssociationPanelTarget) {
      close(this.formRoomAssociationPanelTarget)
    }
  }

  selectFormRoomAssociationType({ currentTarget, params }) {
    desactivate(this.formRoomAssociationTypeTargets)
    activate([currentTarget])

    hideV2(...this.formRoomAssociationItemTargets)
    showV2(...this.formRoomAssociationItemTargets.filter(
      ({ dataset }) => dataset.associationType == params.associationType
    ))
  }

  selectFormRoomAssociation({ currentTarget }) {
    desactivate(this.formRoomAssociationItemTargets)
    activate([currentTarget])

    this.selectedFormRoomAssociationTarget.textContent = currentTarget.innerText.trim()
    this.selectedFormRoomAssociationTarget.classList.remove('placeholder')
    showV2(this.clearAssociationButtonTarget)

    this.setAssociationSelect(currentTarget)

    close(this.formRoomAssociationPanelTarget)
  }

  toggle() {
    toggleOpen(this.formRoomAssociationPanelTarget)
  }

  clearAssociation(event) {
    event.stopPropagation()
    const defaultType = this.formRoomAssociationTypeTargets[0]
    desactivate([...this.formRoomAssociationTypeTargets, ...this.formRoomAssociationItemTargets])
    activate([defaultType])
    hideV2(...this.formRoomAssociationItemTargets)
    showV2(...this.formRoomAssociationItemTargets.filter(
      ({ dataset }) => dataset.associationType == defaultType.dataset.reglementaryRoomAssociationTypeParam
    ))

    this.selectedFormRoomAssociationTarget.classList.add('placeholder')
    this.selectedFormRoomAssociationTarget.textContent = this.selectedFormRoomAssociationTarget.dataset.placeholder
    this.sensorSelectTarget.value = ""
    if (this.hasRoomSelectTarget) {
      this.roomSelectTarget.value = ""
    }
    this.nameFieldTarget.value = ""
    this.floorFieldTarget.value = 0
    hideV2(this.clearAssociationButtonTarget)
  }

  setAssociationSelect({textContent, dataset}) {
    if (dataset.associationType === 'rooms') {
      this.sensorSelectTarget.value = ""
      this.sensorSelectTarget.disabled = true
      this.roomSelectTarget.disabled = false
      this.roomSelectTarget.value = dataset.associationId
      this.nameFieldTarget.value = textContent
      this.floorFieldTarget.value = dataset.floor
    } else {
      this.sensorSelectTarget.disabled = false
      if (this.hasRoomSelectTarget) {
        this.roomSelectTarget.disabled = true
        this.roomSelectTarget.value = ""
      }
      this.sensorSelectTarget.value = dataset.associationId
      this.nameFieldTarget.value = ""
    }
  }

  initField({associationType, value}) {
    const currentTarget = this.formRoomAssociationTypeTargets.find(
      ({dataset}) => dataset.reglementaryRoomAssociationTypeParam == associationType
    )
    const item = this.formRoomAssociationItemTargets.find(
      ({dataset}) => dataset.associationType == associationType && dataset.associationId == value
    )

    this.selectFormRoomAssociationType({currentTarget, params: {associationType}})
    desactivate(this.formRoomAssociationItemTargets)
    activate([item])

    this.selectedFormRoomAssociationTarget.textContent = item.innerText.trim()
    this.selectedFormRoomAssociationTarget.classList.remove('placeholder')
    showV2(this.clearAssociationButtonTarget)
  }
}