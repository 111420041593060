import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "subheader", "horizontalTab"];

  connect() {
    if (window.innerWidth < 1024) {
      window.addEventListener('scroll', () => this.updatePosition());
    }
  }

  disconnect() {
    window.removeEventListener('scroll', () => this.updatePosition());
  }

  updatePosition() {
    this.header = this.headerTarget.offsetHeight;
    this.subheader = this.subheaderTarget.offsetHeight;
    const combinedHeight = this.header + this.subheader

    const rect = this.element.getBoundingClientRect();

    if (rect.top <= -combinedHeight) {
      this.horizontalTabTarget.style.position = 'sticky';
      this.horizontalTabTarget.style.top = '0';
    }
  }
}
