import { Controller } from "@hotwired/stimulus";
import { desactivate, hideV2, showV2 } from "../utils/display";

const initType = 'custom-select:initField'

const setSelected = (field, value, selectedTargets) => {
  const selectedInput = selectedTargets.find(element => element.dataset.field === field)
  const { multiple, noOptionPlaceholder, placeholder } = selectedInput.dataset

  if (selectedInput && multiple !== 'true') {
    switch (value) {
      case 'noOption':
        selectedInput.innerHTML = noOptionPlaceholder
        break
      case 'placeholder':
        selectedInput.classList.add('placeholder')
        selectedInput.innerHTML = placeholder
        break
      default:
        selectedInput.classList.remove('placeholder')
        selectedInput.innerHTML = value
        break
    }
  }
  
  if (multiple === 'true' && ['noOption', 'placeholder'].includes(value)) {
    selectedInput.innerHTML = ''
  }
}

const enableDropdown = (field, dropdownTargets) => {  
  dropdownTargets.filter(element => element.dataset.field === field)[0].classList.remove('is-disabled')
}

const disableDropdown = (field, dropdownTargets) => {
  dropdownTargets.filter(element => element.dataset.field === field)[0].classList.add('is-disabled')
}

const toggleOptions = (options, field, value) => {
  const optionItems = options.filter(({ dataset }) => dataset.associatedField === field)
                             .flatMap(({ children }) => Array.from(children))
  
  desactivate(optionItems)
  hideV2(...optionItems)
  showV2(...optionItems.filter(({ dataset }) => dataset[field.replace(/(_\w)/g, k => k[1].toUpperCase())] == value))
}

export default class extends Controller {
  static targets = ['options', 'select', 'selected', 'dropdown']

  updateSelect({detail, type}) {
    const {field, value}  = detail

    toggleOptions(this.optionsTargets, field, value)
    
    const selectsList = this.selectTargets.filter((select) => select.dataset.associatedField === field)
    selectsList.forEach(select => {
      const availableOptions = this.optionsTargets.find(option => option.dataset.field === select.dataset.field).querySelectorAll("li:not(.is-hidden)")
      const defaultOption = availableOptions.length ? availableOptions[0].dataset : {}
      this.setSelect(select, defaultOption, type)
      this.updateSelect({type, detail: {field: select.dataset.field, value: select.value}})
    }, this)
  }

  setSelect(select, option, eventType) {
    const { field } = select.dataset
    const { label, customSelectValueParam } = option

    if (select.value && eventType === initType) {
      setSelected(field, select.selectedOptions[0].label, this.selectedTargets)
      enableDropdown(field, this.dropdownTargets)
    } else {
      if (select.required) {
        select.value = customSelectValueParam
        setSelected(field, label, this.selectedTargets)
        enableDropdown(field, this.dropdownTargets)
      } else if (label) {
        select.selectedIndex = 0
        setSelected(field, 'placeholder', this.selectedTargets)
        enableDropdown(field, this.dropdownTargets)
      } else {
        select.selectedIndex = 0
        setSelected(field, 'noOption', this.selectedTargets)
        disableDropdown(field, this.dropdownTargets)
      }
    } 
  }
}
